import { useSearchParams } from "react-router-dom"
import { formatValue } from "./utils"
import { GroupedReferences } from "."
import { usePotentialRevenue } from "@/hooks/usePotentialRevenue"
import { StoreReducerState } from "@/reducers/storeReducer"

interface GroupContentProps {
  storeStoreSuppliers: StoreReducerState["storeStoreSuppliers"]
  storeCurrency: StoreReducerState["storeCurrency"]
  supplierData: GroupedReferences
}

export const HeaderGroup = ({
  supplierData,
  storeCurrency,
  storeStoreSuppliers,
}: GroupContentProps) => {
  const [searchParams] = useSearchParams()
  const { totalOrders, potentialCommandRevenue, orderPrice } =
    usePotentialRevenue({ references: supplierData.products })

  const orderId = searchParams.get("orderId")
  const currentWeekday = Intl.DateTimeFormat("fr-FR", {
    weekday: "long",
  }).format(new Date())
  const minimalOrderValue =
    storeStoreSuppliers?.find(
      (storeSupplier) =>
        storeSupplier.supplier_id === supplierData.supplierId &&
        storeSupplier.order_day === currentWeekday,
    )?.minimal_order_value ?? undefined
  const isOrderValueHighlighted =
    minimalOrderValue !== undefined &&
    (orderPrice ?? 0) < minimalOrderValue &&
    (typeof orderId !== "string" || orderId === "")

  return (
    <>
      <p className="text-l font-bold whitespace-nowrap col-span-4 md:col-span-3 lg:col-span-4">
        {supplierData.supplierName}
      </p>
      {minimalOrderValue !== undefined &&
        minimalOrderValue > 0 &&
        (typeof orderId !== "string" || orderId === "") && (
          <span>
            Franco :{" "}
            <span>{formatValue(minimalOrderValue, storeCurrency)}</span>
          </span>
        )}
      <p className="hidden md:block font-semibold md:col-span-2 lg:col-span-1 text-gray-900 text-center">
        {totalOrders === undefined || isNaN(totalOrders)
          ? "-"
          : `${totalOrders} cs`}
      </p>
      <p
        className={`hidden md:block text-lg font-semibold ${isOrderValueHighlighted ? "text-red-500" : "text-gray-900"} text-center`}
      >
        {orderPrice === undefined || isNaN(orderPrice)
          ? "-"
          : formatValue(orderPrice, storeCurrency)}
      </p>
      <p className="hidden md:block font-semibold text-gray-900 text-center">
        {potentialCommandRevenue === undefined || isNaN(potentialCommandRevenue)
          ? "-"
          : formatValue(potentialCommandRevenue, storeCurrency)}
      </p>
    </>
  )
}
