import { usePotentialRevenue } from "@/hooks/usePotentialRevenue"
import { formatValue } from "./utils"
import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { StoreReducerState } from "@/reducers/storeReducer"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { GetOrderItem } from "@/utils/__generated__/graphql"
import { Send } from "lucide-react"

interface TotalDisplayProps {
  storeCurrency: StoreReducerState["storeCurrency"]
  references: (AllMercurialInfo | GetOrderItem)[]
  handleValidateOrderPreview: () => void
  isOrderConfirmed: boolean
  orderId: string | null
  enable: boolean
}
const TotalDisplay = ({
  storeCurrency,
  references,
  handleValidateOrderPreview,
  isOrderConfirmed,
  orderId,
  enable,
}: TotalDisplayProps) => {
  const { totalOrders, potentialCommandRevenue, orderPrice } =
    usePotentialRevenue({ references })
  const isAnyValueNaN =
    (totalOrders !== undefined && isNaN(totalOrders)) ||
    (orderPrice !== undefined && isNaN(orderPrice)) ||
    (potentialCommandRevenue !== undefined && isNaN(potentialCommandRevenue))

  return (
    <Card
      className={`z-10 absolute bottom-safe-bottom lg:bottom-[30px] min-w-full lg:min-w-[700px] right-0 lg:right-5 flex flex-col bg-white rounded-t-lg lg:rounded gap-4 p-4 items-center text-base lg:text-lg`}
      style={{ boxShadow: "0 4px 20px 10px rgba(0, 0, 0, 0.2)" }}
    >
      <div className="flex w-full flex-col lg:flex-row justify-between">
        <div className="flex w-full flex-col justify-center lg:flex-row lg:justify-between items-center gap-4">
          <div className="flex flex-row justify-around w-full">
            <div className="flex flex-col text-right md:text-center">
              <p className="text-xs lg:text-base">Colis</p>
              <p className="text-black text-sm md:text-base font-black">
                {totalOrders === undefined || isNaN(totalOrders)
                  ? "-"
                  : `${totalOrders} cs`}
              </p>
            </div>

            <div className="flex flex-col text-right md:text-center">
              <p className="text-xs lg:text-base">
                Coût total <span className="text-xxs xs:text-xs">(HT)</span>
              </p>
              <p className="text-black text-sm md:text-base font-black">
                {orderPrice === undefined || isNaN(orderPrice)
                  ? "-"
                  : formatValue(orderPrice, storeCurrency)}
              </p>
            </div>

            <div className="flex flex-col text-right md:text-center">
              <p className="text-xs lg:text-base">
                CA Potentiel <span className="text-xxs xs:text-xs">(TTC)</span>
              </p>
              <p className="text-black text-sm md:text-base font-black">
                {potentialCommandRevenue === undefined ||
                isNaN(potentialCommandRevenue)
                  ? "-"
                  : formatValue(potentialCommandRevenue, storeCurrency)}
              </p>
            </div>
          </div>
          {orderId === null && !isOrderConfirmed && (
            <Button
              className={`${!enable ? "bg-green-800 hover:bg-green-900" : "bg-orange-400 hover:bg-orange-500"} text-white h-14 w-full lg:w-64 lg:ml-4`}
              onClick={handleValidateOrderPreview}
              disabled={isAnyValueNaN}
            >
              <Send />
            </Button>
          )}
        </div>
      </div>
    </Card>
  )
}

export default TotalDisplay
