import { Button } from "../ui/button"
import { Input } from "../ui/input"

interface Props {
  quantity: number
  onReducedQuantity: () => void
  onIncreasedQuantity: () => void
  onQuantityChange: (quantity: number) => void
}

export const QuantitySelector = ({
  quantity,
  onReducedQuantity,
  onIncreasedQuantity,
  onQuantityChange,
}: Props) => {
  return (
    <div className="flex items-center">
      <Input
        type="number"
        value={quantity}
        className="min-w-14 min-h-14 md:min-w-10 md:min-h-10 w-20 text-center p-0 m-0 rounded-r-none rounded-l-md border-r-0"
        onChange={(e) =>
          onQuantityChange(Math.max(0, parseInt(e.target.value)))
        }
      />
      <Button
        className="min-w-14 min-h-14 md:min-w-10 md:min-h-10 rounded-none border-r-0"
        variant="outline"
        size="icon"
        onClick={onReducedQuantity}
      >
        -
      </Button>
      <Button
        className="min-w-14 min-h-14 md:min-w-10 md:min-h-10 rounded-l-none rounded-r-md"
        variant="outline"
        size="sm"
        onClick={onIncreasedQuantity}
      >
        +
      </Button>
    </div>
  )
}
