import { ChangeEvent, useMemo, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { type DispatchActionType, type StateType } from "../../types"
import { getUnit } from "../../utils/getUnit"
import { toFixedNumber } from "../../utils/toFixedNumber"
import { InventoryType } from "../../utils/__generated__/graphql"
import { twMerge } from "tailwind-merge"
import OutstandingOrders from "./OustandingOrders"
import { useWindowSize } from "../../hooks/useWindowSize"
import { Button } from "../../ui/Button"
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline"
import { getQuarterValue } from "../../utils/getQuarterValue"
import {
  InventoryInputMobile,
  RingColor,
  SelectorData,
} from "../../ui/InventoryInputMobile"
import { SelectorLabel } from "../../ui/SelectorLabel"

interface BaseInventoryProps {
  stock_too_high_flag?: boolean | undefined | null
  stock_too_low_flag?: boolean | undefined | null
  stock_to_verify_flag?: boolean | undefined | null
  isPrecommande?: boolean
  prediction_uncertain?: boolean | undefined | null
  mercurialeId: string
  unit: string
  waitedQuantityOrdered?: number
  promoWaitedQuantityOrdered?: number
  name: InventoryType
  value: number | null
  disabled?: boolean
  className?: string
  updateInventory: (
    value: string,
    selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
  ) => Promise<void>
  minimal?: boolean
  isFirstRow?: boolean
  isNewReference?: boolean
  floorInventoryQuantity?: number
}

export function BaseInventory({
  prediction_uncertain,
  stock_too_high_flag,
  stock_too_low_flag,
  stock_to_verify_flag,
  isPrecommande = false,
  isFirstRow,
  mercurialeId,
  unit,
  waitedQuantityOrdered,
  promoWaitedQuantityOrdered,
  value,
  name,
  disabled = false,
  updateInventory,
  className,
  minimal = false,
  isNewReference = false,
  floorInventoryQuantity,
}: BaseInventoryProps): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const inputRef = useRef<HTMLInputElement>(null)
  const { storeSettings } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const mergedClassName = twMerge(
    "flex justify-center lg:justify-start",
    className,
  )
  const numericKeypadValue = useSelector(
    (state: StateType) => state.userInterfaceReducer.numericPadValue,
  )
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  // Local state to handle input value for better user experience with keyboard on mobile
  const [localValue, setLocalValue] = useState<string>()

  const computedValue = toFixedNumber(value ?? 0)

  const parsedNumericKeypadValue = useMemo(() => {
    const parsedValue = parseFloat(numericKeypadValue)
    if (
      selectedInventory?.type !== name ||
      selectedInventory?.mercurialeId !== mercurialeId ||
      isNaN(parsedValue)
    )
      return
    return parsedValue
  }, [
    mercurialeId,
    name,
    numericKeypadValue,
    selectedInventory?.mercurialeId,
    selectedInventory?.type,
  ])

  const displayedUnit =
    storeSettings?.use_kg_pce === true ? getUnit(unit) : "cs"

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      void updateInventory("0")
      setLocalValue("0")
      return
    }

    const parsedValue = parseFloat(event.target.value)
    if (isNaN(parsedValue)) return

    const stringifiedValue = `${parsedValue}`

    if (event.target.value.endsWith(".") || event.target.value.endsWith(",")) {
      setLocalValue(stringifiedValue + ".")
      void updateInventory(stringifiedValue)
      return
    }

    const integer = stringifiedValue.split(".")[0]

    setLocalValue(integer)
    void updateInventory(stringifiedValue)
  }

  const { isMD, isLG } = useWindowSize()

  const buttonClassName = "w-8 h-8 rounded-full p-0"

  const quarterIcon = getQuarterValue(
    parsedNumericKeypadValue ?? computedValue,
    "w-4 h-4 lg:w-5 lg:h-5",
  ).icon

  const ringColor: RingColor | undefined = useMemo(() => {
    if (stock_to_verify_flag) return "red"
    if (stock_too_high_flag) return "orange"
    if (stock_too_low_flag) return "lightOrange"
    if (
      isNewReference &&
      (parsedNumericKeypadValue ?? computedValue) === 0 &&
      value === null
    ) {
      return "yellow"
    }
  }, [
    computedValue,
    isNewReference,
    parsedNumericKeypadValue,
    stock_to_verify_flag,
    stock_too_high_flag,
    stock_too_low_flag,
    value,
  ])

  const selectorData = useMemo<SelectorData<number>[] | undefined>(() => {
    if (name !== InventoryType.Shelf || computedValue <= 0) return

    const fullValue = getQuarterValue(computedValue, "size-4")
    const threeQuartersValue = getQuarterValue(computedValue * 0.75, "size-4")
    const halfValue = getQuarterValue(computedValue * 0.5, "size-4")
    const oneQuarterValue = getQuarterValue(computedValue * 0.25, "size-4")

    return [
      {
        label: (
          <SelectorLabel
            text="PLEIN"
            value={fullValue.value}
            icon={fullValue.icon}
            unit={displayedUnit}
          />
        ),
        selectedLabel: <SelectorLabel text="PLEIN" />,
        value: (fullValue.value ?? 0) + (fullValue.decimal ?? 0),
      },
      {
        label: (
          <SelectorLabel
            text="75%"
            value={threeQuartersValue.value}
            icon={threeQuartersValue.icon}
            unit={displayedUnit}
          />
        ),
        selectedLabel: <SelectorLabel text="75%" />,
        value:
          (threeQuartersValue.value ?? 0) + (threeQuartersValue.decimal ?? 0),
      },
      {
        label: (
          <SelectorLabel
            text="50%"
            value={halfValue.value}
            icon={halfValue.icon}
            unit={displayedUnit}
          />
        ),
        selectedLabel: <SelectorLabel text="50%" />,
        value: (halfValue.value ?? 0) + (halfValue.decimal ?? 0),
      },
      {
        label: (
          <SelectorLabel
            text="25%"
            value={oneQuarterValue.value}
            icon={oneQuarterValue.icon}
            unit={displayedUnit}
          />
        ),
        selectedLabel: <SelectorLabel text="25%" />,
        value: (oneQuarterValue.value ?? 0) + (oneQuarterValue.decimal ?? 0),
      },
      {
        label: (
          <SelectorLabel
            text="VIDE"
            value={0}
            unit={displayedUnit}
            displayZero
          />
        ),
        selectedLabel: <SelectorLabel text="VIDE" />,
        value: 0,
      },
    ]
  }, [computedValue, displayedUnit, name])

  const selectorValue = useMemo<number | undefined>(() => {
    const quarterValue = getQuarterValue(floorInventoryQuantity)
    return (quarterValue.value ?? 0) + (quarterValue.decimal ?? 0)
  }, [floorInventoryQuantity])

  return isMD ? (
    <div className={mergedClassName}>
      {name === InventoryType.Back && isLG && (
        <div className=" flex flex-col gap-2 relative">
          {stock_to_verify_flag && isFirstRow ? (
            <div className="absolute truncate left-7 -top-9 pr-2 pl-2 px-6 py-0.2 bg-red-500 rounded-sm text-custom-white justify-center items-center font-satoshi text-xs uppercase font-bold">
              À VÉRIFIER
            </div>
          ) : stock_too_high_flag && isFirstRow ? (
            <div className="absolute truncate left-5  -top-9 pr-1 pl-1 px-6 py-0.2 bg-custom-orange rounded-sm text-custom-white  justify-center items-center  font-satoshi text-xs uppercase font-bold">
              STOCK ÉLEVÉ
            </div>
          ) : (
            stock_too_low_flag &&
            isFirstRow && (
              <div className="absolute truncate left-5 -top-9 pr-2 pl-2 px-6 py-0.2 bg-custom-yellow rounded-sm text-custom-white justify-center items-center font-satoshi text-xs uppercase font-bold">
                STOCK FAIBLE
              </div>
            )
          )}

          <Button
            disabled={disabled}
            onClick={() => {
              dispatch({
                type: "setSelectedInventory",
                payload: undefined,
              })
              const _value =
                (value ?? 0) < 0 || stock_to_verify_flag === true
                  ? 0
                  : (value ?? 0)
              void updateInventory(`${_value + 1}`, {
                type: name,
                mercurialeId: mercurialeId ?? "",
              })
            }}
            size="custom"
            color="lightGreen"
            className={buttonClassName}
          >
            <PlusIcon className="w-3 h-3" />
          </Button>
          <Button
            disabled={
              disabled || (value ?? 0) <= 0 || stock_to_verify_flag === true
            }
            onClick={() => {
              dispatch({
                type: "setSelectedInventory",
                payload: undefined,
              })
              const _value = value ?? 0
              if (_value <= 0) return
              if (_value < 1) {
                void updateInventory("0", {
                  type: name,
                  mercurialeId: mercurialeId ?? "",
                })
                return
              }
              void updateInventory(`${_value - 1}`, {
                type: name,
                mercurialeId: mercurialeId ?? "",
              })
            }}
            size="custom"
            color="lightGreen"
            className={buttonClassName}
          >
            <MinusIcon className="w-4 h-4" />
          </Button>
        </div>
      )}
      {name === InventoryType.Order && isLG && (
        <div className="flex relative">
          {isPrecommande ? (
            <div className="absolute truncate left-4  -top-9 pr-1 pl-1 px-6 py-0.2 bg-cyan-700 rounded-sm text-custom-white  justify-center items-center  font-satoshi text-xs uppercase font-bold">
              Préco
            </div>
          ) : prediction_uncertain ? (
            <div className="absolute truncate -top-9 pr-1 pl-1 px-6 py-0. bg-red-600 rounded-sm text-custom-white justify-center items-center text-center font-satoshi text-xs uppercase font-bold">
              Peu Fiable
            </div>
          ) : null}
        </div>
      )}
      <div className="relative flex gap-2 lg:gap-0 items-center lg:items-start flex-col lg:flex-row ">
        <div
          onClick={() => {
            if (disabled || !isLG) return
            inputRef.current?.focus()
          }}
          className={
            stock_to_verify_flag
              ? `p-1 lg:p-0 ${disabled ? "cursor-not-allowed" : "cursor-pointer"} flex flex-row items-center justify-center lg:gap-1 relative bg-custom-gray ring-4 ring-inset ring-red-500 text-base z-40 mr-0 text-center h-fit lg:h-[73px] w-fit lg:w-[73px] rounded-full caret-transparent`
              : stock_too_high_flag
                ? `p-1 lg:p-0 ${disabled ? "cursor-not-allowed" : "cursor-pointer"} flex flex-row items-center justify-center lg:gap-1 relative bg-custom-gray ring-4 ring-inset ring-custom-orange text-base z-40 mr-0 text-center h-fit lg:h-[73px] w-fit lg:w-[73px] rounded-full caret-transparent`
                : stock_too_low_flag
                  ? `p-1 lg:p-0 ${disabled ? "cursor-not-allowed" : "cursor-pointer"} flex flex-row items-center justify-center lg:gap-1 relative bg-custom-gray ring-4 ring-inset ring-custom-yellow text-base z-40 mr-0 text-center h-fit lg:h-[73px] w-fit lg:w-[73px] rounded-full caret-transparent`
                  : isPrecommande
                    ? `p-1 lg:p-0 ${disabled ? "cursor-not-allowed" : "cursor-pointer"} flex flex-row items-center justify-center lg:gap-1 relative bg-custom-gray ring-4 ring-inset ring-cyan-700 text-base z-40 mr-0 text-center h-fit lg:h-[73px] w-fit lg:w-[73px] rounded-full caret-transparent`
                    : `p-1 lg:p-0 ${disabled ? "cursor-not-allowed" : "cursor-pointer"} flex flex-row items-center justify-center lg:gap-1 relative ${minimal ? "bg-transparent" : "bg-zinc-100"} text-base z-40 mr-0 text-center h-fit lg:h-[73px] w-fit lg:w-[73px] rounded-full caret-transparent ${
                        mercurialeId === selectedInventory?.mercurialeId &&
                        selectedInventory?.type === name &&
                        !disabled &&
                        !minimal
                          ? (value ?? 0) < 0
                            ? "ring-4 ring-inset ring-red-500"
                            : "lg:ring-4 lg:ring-inset lg:ring-green-500 lg:focus:ring-green-500"
                          : ""
                      }`
          }
        >
          {!minimal && (
            <div className="hidden lg:block absolute top-[-12px] right-0 bg-zinc-400 rounded-md w-8 h-5 pt-[2px]">
              <p className="text-white text-sm leading-3 font-medium w-full text-center">
                {displayedUnit}
              </p>
            </div>
          )}
          {!isLG && (
            <Button
              disabled={disabled || (value ?? 0) <= 0}
              onClick={() => {
                const _value = value ?? 0
                if (_value <= 0) return
                if (_value < 1) {
                  void updateInventory("0", {
                    type: name,
                    mercurialeId: mercurialeId ?? "",
                  })
                  return
                }
                void updateInventory(`${_value - 1}`, {
                  type: name,
                  mercurialeId: mercurialeId ?? "",
                })
              }}
              size="custom"
              color={name === InventoryType.Shelf ? "darkGreen" : "lightGreen"}
              className={buttonClassName}
            >
              <MinusIcon className="w-4 h-4" />
            </Button>
          )}
          <div
            className={`flex flex-1 items-center lg:gap-1 ${minimal ? "bg-zinc-100 mx-0.5 py-1 lg:bg-transparent rounded" : ""} ${
              disabled
                ? "text-gray-300"
                : mercurialeId === selectedInventory?.mercurialeId &&
                    selectedInventory?.type === name
                  ? "text-green-500"
                  : (value ?? 0) < 0
                    ? "text-red-500"
                    : minimal
                      ? "text-neutral-700 lg:text-gray-300"
                      : "text-black"
            }`}
          >
            <input
              ref={inputRef}
              type={isMD ? "" : "number"}
              inputMode={isMD ? "none" : "decimal"}
              disabled={disabled}
              name={`${name}-${mercurialeId}`}
              onFocus={() => {
                dispatch({
                  type: "setSelectedInventory",
                  payload: {
                    type: name,
                    mercurialeId: mercurialeId ?? undefined,
                  },
                })
                // This logic aims to use fresh value when user click on the input and user keyboard to update input
                setLocalValue("")
                if (inputRef.current === null) return
                inputRef.current.placeholder = inputRef.current?.value
              }}
              onBlur={() => {
                setLocalValue(undefined)
              }}
              onChange={handleChange}
              placeholder={
                (value ?? 0) < 0 || stock_to_verify_flag === true ? "-" : ""
              }
              value={
                stock_to_verify_flag === true
                  ? "-"
                  : (getQuarterValue(parsedNumericKeypadValue).value ??
                    ((value ?? 0) < 0 ||
                    (name === InventoryType.Shelf && value === null)
                      ? ""
                      : (localValue ?? getQuarterValue(computedValue).value)))
              }
              className={`disabled:selection:bg-transparent disabled:selection:text-gray-300 w-full appearance-none max-w-24 flex-1 cursor-pointer disabled:cursor-not-allowed flex bg-transparent text-xs lg:text-base focus:ring-0 focus:outline-none font-bold ${quarterIcon && stock_to_verify_flag !== true ? "text-right" : "text-center"} h-fit lg:h-[24px] ${
                mercurialeId === selectedInventory?.mercurialeId &&
                selectedInventory?.type === name
                  ? disabled
                    ? "placeholder:text-gray-300 text-gray-300 focus:text-gray-300"
                    : "placeholder:text-green-500 text-green-500 focus:text-green-500"
                  : disabled
                    ? "placeholder:text-gray-300 text-gray-300"
                    : (value ?? 0) < 0
                      ? "placeholder:text-red-500 text-red-500"
                      : minimal
                        ? "placeholder:text-neutral-700 text-neutral-700 lg:placeholder:text-gray-300 lg:text-gray-300"
                        : "placeholder:text-black text-black"
              } p-0 border-none caret-transparent ${parsedNumericKeypadValue !== undefined || (value !== null && value >= 0 && stock_to_verify_flag !== true) ? "underline" : ""} disabled:no-underline decoration-2`}
            />
            {quarterIcon && stock_to_verify_flag !== true && (
              <div className="flex-1">{quarterIcon}</div>
            )}
          </div>
          {!isLG && (
            <Button
              disabled={disabled}
              onClick={() => {
                const _value = (value ?? 0) < 0 ? 0 : (value ?? 0)
                void updateInventory(`${_value + 1}`, {
                  type: name,
                  mercurialeId: mercurialeId ?? "",
                })
              }}
              size="custom"
              color={name === InventoryType.Shelf ? "darkGreen" : "lightGreen"}
              className={buttonClassName}
            >
              <PlusIcon className="w-3 h-3" />
            </Button>
          )}
          {minimal ? (
            <div className="hidden lg:block ml-1">
              <p
                className={`text-base text-center font-black ${mercurialeId === selectedInventory?.mercurialeId && selectedInventory?.type === name ? "text-black" : "text-gray-300"}`}
              >
                {displayedUnit}
              </p>
            </div>
          ) : (
            <div className="lg:hidden absolute -bottom-2 left-0 z-40 w-full flex justify-center">
              <p className="bg-neutral-100 rounded-sm px-2 pb-0.5 w-fit text-xxs font-bold text-neutral-500">
                {displayedUnit}
              </p>
            </div>
          )}
        </div>
        {name === InventoryType.Back &&
          ((waitedQuantityOrdered ?? 0) > 0 ||
            (promoWaitedQuantityOrdered ?? 0) > 0) && (
            <div
              className={`flex absolute p-2 lg:p-0 top-7 lg:top-0 left-1/2 lg:left-auto -translate-x-1/2 lg:translate-x-0 lg:right-[-55px] flex-row lg:flex-col items-end justify-center rounded-xl ${minimal ? "border-none" : ""} border-2 border-zinc-100 h-fit lg:h-[73px] w-fit lg:w-[100px] lg:pr-1 gap-2 pt-5 lg:pt-0`}
            >
              {(waitedQuantityOrdered ?? 0) > 0 && (
                <OutstandingOrders
                  isPromo={false}
                  quantity={waitedQuantityOrdered}
                  displayedUnit={displayedUnit}
                />
              )}
              {(promoWaitedQuantityOrdered ?? 0) > 0 && (
                <OutstandingOrders
                  isPromo={true}
                  quantity={promoWaitedQuantityOrdered}
                  displayedUnit={displayedUnit}
                />
              )}
            </div>
          )}
        {!isLG &&
          name === InventoryType.Back &&
          ((waitedQuantityOrdered ?? 0) > 0 ||
            (promoWaitedQuantityOrdered ?? 0) > 0) && (
            // Placeholder for waited quantity ordered for mobile size
            <div className="h-8" />
          )}
      </div>
      {isLG && name === InventoryType.Back && (
        // Placeholder for waited quantity ordered for tablet size
        <div className="w-[50px]" />
      )}
    </div>
  ) : (
    <InventoryInputMobile
      name={`${name}-${mercurialeId}`}
      value={
        stock_to_verify_flag === true
          ? -1
          : name === InventoryType.Shelf &&
              floorInventoryQuantity !== undefined &&
              floorInventoryQuantity !== computedValue
            ? floorInventoryQuantity
            : (parsedNumericKeypadValue ?? computedValue)
      }
      onChange={(value) => {
        void updateInventory(value, {
          type: name,
          mercurialeId,
        })
      }}
      onFocus={() => {
        dispatch({
          type: "setSelectedInventory",
          payload: {
            type: name,
            mercurialeId,
          },
        })
      }}
      unit={displayedUnit}
      disabled={disabled}
      selected={
        selectedInventory?.type === name &&
        selectedInventory.mercurialeId === mercurialeId
      }
      ringColor={ringColor}
      selectorValue={selectorValue}
      onSelectorChange={(selectedValue) => {
        void updateInventory(`${selectedValue}`, {
          type: InventoryType.Floor,
          mercurialeId: mercurialeId ?? "",
        })
      }}
      selectorData={selectorData}
      bottomElement={
        <BottomElement
          inventoryType={name}
          value={computedValue}
          ringColor={ringColor}
          unit={displayedUnit}
          floorInventoryQuantity={floorInventoryQuantity}
          isToVerify={stock_to_verify_flag ?? false}
          isTooHigh={stock_too_high_flag ?? false}
          isTooLow={stock_too_low_flag ?? false}
          waitedQuantityOrdered={waitedQuantityOrdered}
          promoWaitedQuantityOrdered={promoWaitedQuantityOrdered}
        />
      }
    />
  )
}

interface EquivalentInventoryProps {
  value: number
  colisage: number
  unit: string
  useKgPce: boolean
  className?: string
  toVerify?: boolean
}

export function EquivalentInventory({
  value,
  colisage,
  unit,
  useKgPce,
  className,
  toVerify = false,
}: EquivalentInventoryProps) {
  const mergedClassName = twMerge("font-black text-sm", className)
  const computedValue = toFixedNumber(value ?? 0)

  return (
    <p className={mergedClassName}>
      {(value ?? 0) < 0 || toVerify
        ? "-"
        : useKgPce === true
          ? toFixedNumber(computedValue / (colisage ?? 1))
          : getUnit(unit) === "pce"
            ? Math.round(computedValue * (colisage ?? 1))
            : toFixedNumber(computedValue * (colisage ?? 1))}{" "}
      {useKgPce === true ? "CS" : getUnit(unit)}
    </p>
  )
}

interface BottomElementProps {
  inventoryType: InventoryType
  value: number
  ringColor?: RingColor
  unit: string
  floorInventoryQuantity?: number
  isToVerify?: boolean
  isTooHigh?: boolean
  isTooLow?: boolean
  waitedQuantityOrdered?: number
  promoWaitedQuantityOrdered?: number
}

function BottomElement({
  inventoryType,
  value,
  ringColor,
  unit,
  floorInventoryQuantity = 0,
  isToVerify = false,
  isTooHigh = false,
  isTooLow = false,
  waitedQuantityOrdered,
  promoWaitedQuantityOrdered,
}: BottomElementProps) {
  switch (inventoryType) {
    // At bottom of back inventory input we add badges and total stock
    case InventoryType.Back: {
      const totalValue = value + floorInventoryQuantity
      const quarterValue = getQuarterValue(totalValue, "size-4")
      return (
        <div className="w-full flex items-center flex-col gap-2">
          <div className="h-[10px] text-xxs font-bold text-center">
            {isToVerify ? (
              <p className="text-red-700">À VÉRIFIER</p>
            ) : isTooHigh ? (
              <p className="text-custom-orange">STOCK ÉLEVÉ</p>
            ) : (
              isTooLow && (
                <p className="text-custom-lightOrange">STOCK FAIBLE</p>
              )
            )}
          </div>
          <p className="w-fit bg-gray-100 border px-2 py-1 rounded text-xxs text-gray-400 text-center flex flex-col gap-1">
            Stock total
            <span className="ml-1 text-xs text-gray-900 font-bold flex justify-center items-center">
              {(totalValue === 0 || (quarterValue.value ?? 0) >= 1) &&
                quarterValue.value}
              {quarterValue.icon} {unit}
            </span>
          </p>
        </div>
      )
    }
    // At bottom of shelf floor size input we add badges
    case InventoryType.Shelf: {
      if (ringColor === "yellow") {
        return (
          <p className="text-xxs text-center font-bold text-yellow-500">
            À RENSEIGNER
          </p>
        )
      }

      if (value === 0) {
        return (
          <div className="flex items-center gap-1">
            <div className="size-5 bg-neutral-500 text-white rounded-full flex justify-center items-center text-xs">
              !
            </div>
            <p className="text-xs font-bold text-neutral-500">
              Pas de capacité
            </p>
          </div>
        )
      }

      return <></>
    }
    // At bottom of order input we add incoming shipments quantity
    case InventoryType.Order: {
      if (
        (waitedQuantityOrdered ?? 0) === 0 &&
        (promoWaitedQuantityOrdered ?? 0) === 0
      ) {
        return <></>
      }
      return (
        <div className="w-full flex items-center flex-col gap-2">
          <div className="h-[10px] text-xxs font-bold text-center">
            {/* TODO: Add order input badges here */}
          </div>
          <div className="w-fit bg-gray-100 border px-2 py-1 rounded flex justify-around">
            {(waitedQuantityOrdered ?? 0) > 0 && (
              <OutstandingOrders
                isPromo={false}
                quantity={waitedQuantityOrdered}
                displayedUnit={unit}
              />
            )}
            {(promoWaitedQuantityOrdered ?? 0) > 0 && (
              <OutstandingOrders
                isPromo={true}
                quantity={promoWaitedQuantityOrdered}
                displayedUnit={unit}
              />
            )}
          </div>
        </div>
      )
    }
  }

  return <></>
}
