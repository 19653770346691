import { useDispatch, useSelector } from "react-redux"

import TotalDisplay from "./totalIsland"
import { GroupedVirtuoso } from "react-virtuoso"

import { useMemo, useRef } from "react"
import { HeaderGroup } from "./headerGroup"

import { TableCell, TableRow } from "@/components/ui/table"

import { ArrowLeftIcon } from "@/ui/icons/ArrowLeftIcon"
import { useWindowSize } from "@/hooks/useWindowSize"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { GetOrderItem, StoreSettings } from "@/utils/__generated__/graphql"
import { DispatchActionType, StateType } from "@/types"
import { RecapSortOption } from "@/reducers/userInterfaceReducer"
import { Button } from "@/components/ui/button"
import { alphabeticalSort, numericalSort } from "@/utils/sort"
import { getUnit } from "@/utils/getUnit"
import { ProductImgAndName } from "@/components/molecules/productImgAndName"

import SearchBar from "./searchBar"
import SortIcon from "./sortIcon"
import { ProductQuantitySelector } from "./productQuantitySelector"

export interface GroupedReferences {
  supplierName: string
  supplierId: string
  products: (AllMercurialInfo | GetOrderItem)[]
}

interface TableProps {
  references: (AllMercurialInfo | GetOrderItem)[]
  excessiveProducts: (AllMercurialInfo | GetOrderItem)[]
  tooLowProducts: (AllMercurialInfo | GetOrderItem)[]
  handleValidateOrderPreview: () => void
  isOrderConfirmed: boolean
  orderId: string | null
  enable: boolean
}

const Table = ({
  references,
  excessiveProducts,
  tooLowProducts,
  handleValidateOrderPreview,
  isOrderConfirmed,
  orderId,
  enable,
}: TableProps) => {
  const dispatch = useDispatch<DispatchActionType>()

  const { storeCurrency, storeSettings, storeStoreSuppliers } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const { sortOption, sortOrder, searchTerm } = useSelector(
    (state: StateType) => state.userInterfaceReducer.recapPage,
  )

  // Keep sort list and option to keep order when updating data
  const oldSortState = useRef<{
    list: string[]
    option: RecapSortOption
    order: "asc" | "desc"
  } | null>(null)

  const columns = useMemo(() => {
    return [
      {
        id: RecapSortOption.Colisage,
        name: "Colisage",
        nameInMobile: "Colisage",
      },
      {
        id: RecapSortOption.PV,
        name: "Prix de vente (TTC)",
        nameInMobile: "PV",
      },
      {
        id: RecapSortOption.QuantityActual,
        name: "Colis commandés",
        nameInMobile: "Colis",
      },
      {
        id: RecapSortOption.PA,
        name: "Coût total (HT)",
        nameInMobile: "PA",
      },
      {
        id: RecapSortOption.PotentialRevenue,
        name: "CA potentiel (TTC)",
        nameInMobile: "CA",
      },
    ]
  }, [])

  const handleSortChange = (columnName: (typeof columns)[number]["id"]) => {
    if (columnName === sortOption) {
      dispatch({
        type: "setSortOption",
        payload: {
          page: "recapPage",
          sortOption: columnName,
          sortOrder: sortOrder === "asc" ? "desc" : "asc",
        },
      })
      return
    }
    dispatch({
      type: "setSortOption",
      payload: {
        page: "recapPage",
        sortOption: columnName,
        sortOrder: "asc",
      },
    })
  }

  const FilterButton = ({
    id,
    name,
    nameInMobile,
  }: (typeof columns)[number]) => {
    const hideInMobile = ["pv", "colisage"].includes(id)

    return (
      <Button
        onClick={() => handleSortChange(id)}
        variant="outline"
        className={`${
          hideInMobile ? "hidden md:block" : ""
        } w-full border-m border-solid text-xs xl:text-sm font-medium text-gray-500 uppercase tracking-wider h-11 ${
          sortOption === id ? "border-green-500" : "border-gray-300"
        }`}
      >
        <div className="flex justify-center items-center h-full md:gap lg:gap-4">
          <span className="text-center">
            <p className="hidden md:block truncate text-xxs">{name}</p>
            <p className="block md:hidden truncate text-xxs">{nameInMobile}</p>
          </span>

          <span className="cursor-pointer lg:block">
            <SortIcon
              column={id}
              sortedColumn={sortOption}
              sortOrder={sortOrder}
            />
          </span>
        </div>
      </Button>
    )
  }

  // Filter and sort logic
  const filteredReferences = useMemo<Partial<AllMercurialInfo>[]>(() => {
    const _references = [...references]

    const computeQuantity = (item: Partial<AllMercurialInfo>) =>
      storeSettings?.use_kg_pce === true
        ? (item.quantity_actual ?? 0) / (item.colisage ?? 1)
        : (item.quantity_actual ?? 0)

    const getSortValue = (
      item: Partial<AllMercurialInfo>,
      option: RecapSortOption | null,
    ) => {
      if (!option) return 0
      switch (option) {
        case RecapSortOption.MercurialeName:
          return item.mercuriale_name ?? ""
        case RecapSortOption.Colisage:
          return item.colisage ?? 1
        case RecapSortOption.PV:
          return item.pv ?? 0
        case RecapSortOption.QuantityActual:
          return computeQuantity(item)
        case RecapSortOption.PA:
          return computeQuantity(item) * (item.pa ?? 0)
        case RecapSortOption.PotentialRevenue:
          return computeQuantity(item) * (item.pv ?? 0)
        default:
          return computeQuantity(item)
      }
    }

    if (
      oldSortState.current?.option === sortOption &&
      oldSortState.current?.order === sortOrder &&
      oldSortState.current?.list
    ) {
      return oldSortState.current.list
        .map((mercurialeId) =>
          _references.find((item) => item.mercuriale_id === mercurialeId),
        )
        .filter(
          (item): item is AllMercurialInfo | GetOrderItem => item !== undefined,
        )
        .filter(
          (item) =>
            searchTerm === "" ||
            item.mercuriale_name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()),
        )
    }

    if (_references.length > 0) {
      const sortedReferences = _references.sort((a, b) => {
        const valueA = getSortValue(a, sortOption)
        const valueB = getSortValue(b, sortOption)

        if (typeof valueA === "string" && typeof valueB === "string") {
          return alphabeticalSort(valueA, valueB, sortOrder)
        }
        return numericalSort(valueA as number, valueB as number, sortOrder)
      })

      oldSortState.current = {
        option: sortOption ?? RecapSortOption.QuantityActual,
        order: sortOrder,
        list: sortedReferences.map((item) => item.mercuriale_id ?? ""),
      }

      return sortedReferences.filter(
        (item) =>
          searchTerm === "" ||
          item.mercuriale_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()),
      )
    }

    return []
  }, [references, searchTerm, sortOption, sortOrder, storeSettings?.use_kg_pce])

  const suppliersData = useMemo(() => {
    return filteredReferences.reduce<GroupedReferences[]>(
      (suppliersData, reference) => {
        const supplierDataIndex = suppliersData.findIndex(
          (supplierData) =>
            supplierData.supplierName === reference.supplier_name,
        )
        if (supplierDataIndex === -1) {
          suppliersData.push({
            supplierName: reference.supplier_name ?? "",
            supplierId: reference.supplier_id ?? "",
            products: [reference],
          })
          return suppliersData
        }
        suppliersData[supplierDataIndex].products.push(reference)
        return suppliersData
      },
      [],
    )
  }, [filteredReferences])

  const groupCounts = useMemo(() => {
    const data = suppliersData.map(({ products }) => products.length)
    return data
  }, [suppliersData])

  const formatCurrency = (value: number, currency: string | null) => {
    return new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      style: "currency",
      currency: currency ?? "EUR",
    }).format(value)
  }

  const getLocalIndex = (
    index: number,
    groupIndex: number,
    groupCounts: number[],
  ) => {
    return index - groupCounts.slice(0, groupIndex).reduce((a, c) => a + c, 0)
  }

  const calculateQuantityActualInUnit = (
    product: AllMercurialInfo | GetOrderItem,
    storeSettings: StoreSettings | null,
  ) => {
    return storeSettings?.use_kg_pce
      ? (product.quantity_actual ?? 0)
      : (product.quantity_actual ?? 0) * (product.colisage ?? 1)
  }

  const getRowClassNames = (
    isLastItem: boolean,
    product: AllMercurialInfo | GetOrderItem,
    isQuantityExcessive: boolean,
    isQuantityTooLow: boolean,
  ) => {
    const baseClasses =
      "grid items-center h-full gap-1 lg:gap-4 p-2 text-base lg:text-lg w-full"

    const lastItemClass = isLastItem ? "mb-4" : ""

    const inactiveProductClass =
      "active" in product && product.active === false ? "opacity-20" : ""

    const quantityIssueClass =
      isQuantityExcessive || isQuantityTooLow ? "rounded my-1 bg-red-30" : ""

    const mobileGridClass = "grid-cols-3 md:grid-cols-7"

    return [
      baseClasses,
      lastItemClass,
      inactiveProductClass,
      quantityIssueClass,
      mobileGridClass,
    ]
      .filter(Boolean)
      .join(" ")
  }

  const handleBack = () => {
    window.history.back()
  }

  const { isMD } = useWindowSize()

  // TODO : stock color in config tailwind
  return (
    <div className="rounded h-full flex flex-col">
      <div className={`flex items-center py-2 ${!isMD ? "px-2" : ""}`}>
        <Button variant="ghost" className="pl-4 " onClick={handleBack}>
          <ArrowLeftIcon />
        </Button>
        <p className="text-xl font-bold ">
          Résumé de commande <span className="md:hidden">🥦🍎</span>
        </p>
        <p className="hidden md:block pl-4 ">
          Un dernier coup d&apos;œil ? Ajustez vos quantités si besoin !! 🥦🍎
        </p>
      </div>
      <div
        className={`sticky top-0 z-10 bg-white backdrop-blur backdrop-filter ${!isMD ? "px-2" : ""}`}
      >
        <div className="block md:hidden pb-2">
          <SearchBar />
        </div>
        <div className="md:grid grid-cols-3 md:grid-cols-6 gap-1 lg:gap-4 content-center items-center md:py-4 hidden">
          <SearchBar />
          {columns.map((column) => (
            <FilterButton
              key={column.id}
              id={column.id}
              name={column.name}
              nameInMobile={column.nameInMobile}
            />
          ))}
        </div>
      </div>
      <GroupedVirtuoso
        className="h-[88%] lg:h-[92%] w-full rounded-md pb-96"
        groupCounts={groupCounts}
        groupContent={(groupIndex) => (
          <TableRow className="px-8 py-2 bg-gray-200 w-full grid grid-cols-2 md:grid-cols-7 md:text-lg lg:text-xl hover:bg-gray-200">
            <HeaderGroup
              supplierData={suppliersData[groupIndex]}
              storeCurrency={storeCurrency}
              storeStoreSuppliers={storeStoreSuppliers}
            />
          </TableRow>
        )}
        itemContent={(index, groupIndex) => {
          const supplier = suppliersData[groupIndex]
          if (!supplier) return null

          const localIndex = getLocalIndex(index, groupIndex, groupCounts)
          const product = supplier.products[localIndex]
          if (!product) return null

          const quantityActualInUnit = calculateQuantityActualInUnit(
            product,
            storeSettings,
          )
          const isQuantityExcessive = excessiveProducts.some(
            (ep) => ep.mercuriale_id === product.mercuriale_id,
          )
          const isQuantityTooLow = tooLowProducts.some(
            (tp) => tp.mercuriale_id === product.mercuriale_id,
          )
          const isLastItem =
            groupIndex === suppliersData.length - 1 &&
            localIndex === supplier.products.length - 1

          const margin =
            typeof product.pv === "number" &&
            typeof product.pa === "number" &&
            product.pv * quantityActualInUnit > 0
              ? (
                  ((product.pv * quantityActualInUnit -
                    product.pa * quantityActualInUnit) /
                    (product.pv * quantityActualInUnit)) *
                  100
                ).toFixed(1)
              : "-"

          return (
            <>
              <TableRow
                className={getRowClassNames(
                  isLastItem,
                  product,
                  isQuantityExcessive,
                  isQuantityTooLow,
                )}
              >
                <TableCell className="flex flex-row justify-between col-span-3 md:col-span-2 lg:col-span-2">
                  <ProductImgAndName product={product} isOnline={online} />
                  <div className="block md:hidden">
                    <ProductQuantitySelector
                      product={product}
                      handleQuantity={true}
                    />
                  </div>
                </TableCell>
                <TableCell className="hidden md:table-cell p-0 text-center">
                  <p className="text-center">
                    {typeof product.colisage === "number"
                      ? `${product.colisage} ${getUnit(product.unit)}`
                      : "-"}
                  </p>
                </TableCell>
                <TableCell className="hidden md:hidden lg:table-cell ">
                  <p className="text-center">
                    {typeof product.pv === "number"
                      ? formatCurrency(product.pv, storeCurrency)
                      : "-"}
                  </p>
                </TableCell>
                <TableCell className="hidden md:table-cell md:col-span-2 lg:col-span-1 justify-center items-center">
                  <ProductQuantitySelector
                    product={product}
                    handleQuantity={true}
                  />
                </TableCell>
                <TableCell className="flex  flex-row gap-2 py-0 px-4 justify-center items-center">
                  <p className="text-gray-400 md:hidden">PA</p>
                  <p>
                    {typeof product.pa === "number" &&
                    !isNaN(product.pa * quantityActualInUnit) &&
                    product.pa * quantityActualInUnit > 0
                      ? formatCurrency(
                          product.pa * quantityActualInUnit,
                          storeCurrency,
                        )
                      : "-"}
                  </p>
                </TableCell>
                <TableCell className="flex flex-row justify-center items-center gap-2 p-0">
                  <p className="text-gray-400 md:hidden">PV</p>
                  <p>
                    {typeof product.pv === "number" &&
                    !isNaN(product.pv * quantityActualInUnit) &&
                    product.pv * quantityActualInUnit > 0
                      ? formatCurrency(
                          product.pv * quantityActualInUnit,
                          storeCurrency,
                        )
                      : "-"}
                  </p>
                </TableCell>
                <TableCell className="flex md:hidden flex-row justify-center items-center gap-2 p-0">
                  <p className="text-gray-400 md:hidden">%</p>
                  <p>{margin}</p>
                </TableCell>
              </TableRow>
              {isLastItem && <div className="h-32"></div>}
            </>
          )
        }}
      />
      <TotalDisplay
        storeCurrency={storeCurrency}
        references={references}
        handleValidateOrderPreview={handleValidateOrderPreview}
        isOrderConfirmed={isOrderConfirmed}
        orderId={orderId}
        enable={enable}
      />
    </div>
  )
}

export default Table
