import { useDispatch, useSelector } from "react-redux"

import {
  InventoryType,
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "../../utils/__generated__/graphql"

import { DispatchActionType, StateType } from "../../types"
import { computeModificationObject } from "../../utils/sendLogsModificationUpdate"
import { captureException } from "@sentry/react"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline"
import { Button } from "../ui/button"
import { useWindowSize } from "@/hooks/useWindowSize"
import { GroupedReferences } from "@/pages/recapPage/components"

const INCREMENT_QUANTITY = "INCREMENT_QUANTITY"
const DECREMENT_QUANTITY = "DECREMENT_QUANTITY"

interface OrderQuantityButtonProps {
  type: typeof DECREMENT_QUANTITY | typeof INCREMENT_QUANTITY
  product: GroupedReferences["products"][number]
  className?: string
}

// Store all timeouts in a global variable with mercuriale id as key (each timeout will be deleted at the end of the function)
const timeouts: Record<string, NodeJS.Timeout> = {}

export function OrderQuantityButton({
  type,
  product,
  className = "",
}: OrderQuantityButtonProps) {
  const dispatch = useDispatch<DispatchActionType>()
  const [batchUpdateMutation] = useBatchUpdateMutation()
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const storeSettings = useSelector(
    (state: StateType) => state.storeReducer.storeSettings,
  )
  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )
  const dimMercurialeId = useSelector(
    (state: StateType) => state.mercurialReducer.selectedDimMercurialeId,
  )
  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) => dimMercuriale.dimMercurialeId === dimMercurialeId,
  )?.dimOrderRequestId

  const handleClick = (
    type: typeof INCREMENT_QUANTITY | typeof DECREMENT_QUANTITY,
  ) => {
    if (!("mercuriale_id" in product) || !("order_id" in product)) return
    const quantity = product.quantity_actual ?? 0
    if (typeof product.mercuriale_id !== "string") return
    if (type === DECREMENT_QUANTITY && quantity <= 0) return
    const colisage =
      storeSettings?.use_kg_pce === true ? (product.colisage ?? 1) : 1
    const value =
      type === INCREMENT_QUANTITY ? quantity + colisage : quantity - colisage

    dispatch({
      type: "updateReference",
      payload: {
        mercurialeId: product.mercuriale_id ?? "",
        orderInventoryQuantity: value,
        isOrderInventoryQuantityUpdated: true,
      },
    })

    clearTimeout(timeouts[product.mercuriale_id])
    timeouts[product.mercuriale_id] = setTimeout(() => {
      const saveModifications = async (): Promise<void> => {
        const modification = computeModificationObject({
          inventoryType: InventoryType.Order,
          mercurialeInfo: product,
          storeSettings: storeSettings ?? {},
          modifiedValue: value,
        })

        try {
          const batchUpdateResult = await batchUpdateMutation({
            variables: {
              input: {
                batch_data: [
                  {
                    order_id: product.order_id,
                    mercuriale_id: product.mercuriale_id!,
                    quantity_actual: value,
                    back_inventory: null, // We don't need back_inventory
                    floor_inventory: null, // We don't need floor_inventory
                    shelf_floor_size: null, // We don't need shelf_floor_size
                    colisage: product.colisage,
                    dim_mercuriale_id: dimMercurialeId,
                    sale_id: null, // We don't need sale_id to update quantity_actual
                  },
                ],
                dim_order_request_id: dimOrderRequestId,
                store_id: storeId ?? "",
                inventory_type: InventoryType.Order,
              },
            },
          })
          if (batchUpdateResult.data?.batchUpdate?.error !== null) {
            captureException(batchUpdateResult.data?.batchUpdate?.error)
            return
          }
          if (
            typeof batchUpdateResult.data.batchUpdate.dim_order_request_id ===
            "string"
          ) {
            dispatch({
              type: "setDimOrderRequestId",
              payload: {
                dimOrderRequestId:
                  batchUpdateResult.data?.batchUpdate.dim_order_request_id,
                dimMercurialeId: product.dim_mercuriale_id ?? "",
              },
            })
          }

          const logsModificationResult = await logsModificationUpdate({
            variables: {
              input: {
                modifications_logs_items: [modification],
                store_id: storeId!,
              },
            },
          })
          if (
            logsModificationResult.data?.logsModificationUpdate?.error !== null
          ) {
            throw logsModificationResult.data?.logsModificationUpdate?.error
          }
        } catch (error) {
          console.error(error)
          const errorMesssage =
            error instanceof Error ? error.message : "Données non sauvegardées"
          captureException(new Error(errorMesssage))
          dispatch({
            type: "setDataSynchronizationStatus",
            payload: DataSynchronizationStatus.FAILURE,
          })
          dispatch({
            type: "addModification",
            payload: modification,
          })
        }
      }
      if (isOnline && !isTestMode) {
        void saveModifications()
      } else {
        dispatch({
          type: "setDataSynchronizationStatus",
          payload: DataSynchronizationStatus.UNSYNCHRONIZED,
        })
      }
      // Delete timeout to avoid memory leaks
      delete timeouts[product.mercuriale_id!]
    }, 1000)
  }

  const { isMD } = useWindowSize()
  const iconSize = isMD ? "w-4 h-4" : "w-5 h-5"

  return (
    <Button
      variant="outline"
      disabled={
        ("active" in product && product.active === false) ||
        (type === DECREMENT_QUANTITY && (product.quantity_actual ?? 0) <= 0)
      }
      onClick={() => handleClick(type)}
      className={"border border-black rounded-sm p-0 " + className}
    >
      {type === DECREMENT_QUANTITY ? (
        <MinusIcon className={iconSize} />
      ) : (
        <PlusIcon className={iconSize} />
      )}
    </Button>
  )
}
