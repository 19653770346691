import {
  ShoppingCartIcon,
  TruckIcon,
  ChartBarIcon,
  HomeModernIcon,
} from "@heroicons/react/24/solid"
import { Label } from "../Label"
import { useEffect, useMemo, useState } from "react"
import { getDiffBetweenDates } from "../../utils/getDiffBetweenDates"
import { twJoin } from "tailwind-merge"
import { DropdownButton } from "./DropdownButton"
import { SaleRow } from "./SaleRow"
import { StockRow } from "./StockRow"
import { PredictionRow } from "./PredictionRow"

interface LastPerformingTableDesktopProps {
  isIdaAdmin: boolean
  data: {
    date: Date
    inventoryHistoricalQuantity: number | undefined
    saleHistoricalQuantity: number | undefined
    deliveryHistoricalQuantity: number | undefined
    OrderHistoricalQuantity: number | undefined
    predictionHistoricalQuantity: number | undefined
    isHighlighted: boolean
    isTooHigh: boolean
    isTooLow: boolean
    modifiedValue: number | undefined
  }[]
  viewSalesFirst: boolean
}
export function LastPerformingTableDesktop({
  data,
  viewSalesFirst,
  isIdaAdmin,
}: LastPerformingTableDesktopProps) {
  const [isLastRowVisible, setIsLastRowVisible] = useState(false)

  useEffect(() => {
    setIsLastRowVisible(false)
  }, [data])

  const toggleSalesRow = () => {
    setIsLastRowVisible(!isLastRowVisible)
  }

  const currentDate = useMemo(() => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
  }, [])

  const transitionStyles = ""

  return (
    <div className={`flex flex-col gap-1 ${transitionStyles}`}>
      <div
        className={`flex text-sm items-center text-neutral-700 ${transitionStyles}`}
      >
        {isIdaAdmin && (
          <Label
            color="white"
            leftIcon={<ChartBarIcon className="size-3" />}
            className="py-0.5 px-1 mx-1 rounded-[5px] shadow-sm flex items-center text-sm"
          >
            Préds
          </Label>
        )}

        <Label
          color="white"
          leftIcon={<TruckIcon className="size-3" />}
          className="py-0.5 px-1 mx-1 rounded-[5px] shadow-sm flex items-center text-sm"
        >
          Livraisons
        </Label>
        <p> {!isIdaAdmin && "et"} </p>
        {viewSalesFirst === true ? (
          <Label
            color="white"
            leftIcon={<ShoppingCartIcon className="size-3" />}
            className="py-0.5 px-1 mx-1 rounded-[5px] shadow-sm flex items-center text-sm"
          >
            Ventes
          </Label>
        ) : (
          <Label
            color="white"
            leftIcon={<HomeModernIcon className="size-3" />}
            className="py-0.5 px-1 mx-1 rounded-[5px] shadow-sm flex items-center text-sm"
          >
            Stocks
          </Label>
        )}
        <DropdownButton
          isOpen={isLastRowVisible}
          onClick={toggleSalesRow}
          icon={
            viewSalesFirst === true ? (
              <HomeModernIcon className="size-3.5" />
            ) : (
              <ShoppingCartIcon className="size-3.5" />
            )
          }
          text={viewSalesFirst === true ? "Stocks" : "Ventes"}
        />
      </div>
      <div
        className={`flex text-center text-sm text-zinc-500 ${transitionStyles}`}
      >
        <div
          className={twJoin("flex rounded w-fit bg-zinc-100", transitionStyles)}
        >
          <div className="border-r flex flex-col">
            <p className="flex-1 px-2 py-1 flex items-center border-b">cs.</p>
            {isIdaAdmin && (
              <div className="px-2 py-1 leading-4 flex">
                <ChartBarIcon className="size-4" />
              </div>
            )}
            <div className="px-2 py-1 leading-4 flex">
              <TruckIcon className="size-4" />
            </div>
            <div className="px-2 py-1 leading-4 flex">
              {viewSalesFirst === true ? (
                <ShoppingCartIcon className="size-4" />
              ) : (
                <HomeModernIcon className="size-4" />
              )}
            </div>
            <div
              className={twJoin(
                "px-2  font-black leading-4 flex gap-0.5 rounded",
                transitionStyles,
                isLastRowVisible
                  ? "visible py-1 opacity-100"
                  : "invisible opacity-0",
                "h-0",
                isLastRowVisible ? "h-auto" : "",
              )}
            >
              {viewSalesFirst === true ? (
                <HomeModernIcon className="size-4" />
              ) : (
                <ShoppingCartIcon className="size-4" />
              )}
            </div>
          </div>
          {data?.map(
            (
              {
                date,
                inventoryHistoricalQuantity,
                predictionHistoricalQuantity,
                saleHistoricalQuantity,
                deliveryHistoricalQuantity,
                isHighlighted,
                OrderHistoricalQuantity,
                isTooHigh,
                isTooLow,
                modifiedValue,
              },
              index,
            ) => {
              const diffBetweenDates = getDiffBetweenDates(currentDate, date)
              const isYesterday = diffBetweenDates === -1
              const isToday = diffBetweenDates === 0

              return (
                <div
                  key={index}
                  className={twJoin(
                    "flex flex-col text-xs rounded",
                    isHighlighted && "bg-green-20",
                  )}
                >
                  <p
                    className={twJoin(
                      "flex-1 flex justify-center items-center capitalize p-1 border-b",
                      isHighlighted && "text-green-900 border-green-900",
                    )}
                  >
                    {isYesterday
                      ? "Hier"
                      : isToday
                        ? "Ajd."
                        : Intl.DateTimeFormat("fr-FR", {
                            weekday: "short",
                          }).format(date)}
                  </p>

                  {isIdaAdmin && (
                    <div
                      className={twJoin(
                        "p-1 font-black leading-4 flex gap-0.5 rounded",
                        isHighlighted && "bg-green-20 text-green-900",
                      )}
                    >
                      <PredictionRow
                        orderHistoricalQuantity={OrderHistoricalQuantity}
                        predictionHistoricalQuantity={
                          predictionHistoricalQuantity
                        }
                      />
                    </div>
                  )}

                  <div
                    className={twJoin(
                      "p-1 font-black leading-4 flex gap-0.5 rounded",
                      isHighlighted && "bg-green-20 text-green-900",
                    )}
                  >
                    <p className="w-full flex justify-center items-center gap-1">
                      {deliveryHistoricalQuantity !== undefined
                        ? Math.round(deliveryHistoricalQuantity)
                        : "-"}
                    </p>
                  </div>
                  <div
                    className={twJoin(
                      "p-1 font-black leading-4 flex gap-0.5 rounded",
                      isHighlighted && "bg-green-20 text-green-900",
                    )}
                  >
                    {viewSalesFirst ? (
                      <SaleRow
                        saleHistoricalQuantity={saleHistoricalQuantity}
                      />
                    ) : (
                      <StockRow
                        modifiedValue={modifiedValue}
                        inventoryHistoricalQuantity={
                          inventoryHistoricalQuantity
                        }
                        isTooHigh={isTooHigh}
                        isTooLow={isTooLow}
                      />
                    )}
                  </div>
                  <div
                    className={twJoin(
                      "px-1 font-black leading-4 flex gap-0.5 rounded",
                      isHighlighted && "bg-green-20 text-green-900",
                      transitionStyles,
                      isLastRowVisible
                        ? "visible py-1 opacity-100 h-auto"
                        : "invisible opacity-0 h-0",
                    )}
                  >
                    {viewSalesFirst ? (
                      <StockRow
                        modifiedValue={modifiedValue}
                        inventoryHistoricalQuantity={
                          inventoryHistoricalQuantity
                        }
                        isTooHigh={isTooHigh}
                        isTooLow={isTooLow}
                      />
                    ) : (
                      <SaleRow
                        saleHistoricalQuantity={saleHistoricalQuantity}
                      />
                    )}
                  </div>
                </div>
              )
            },
          )}
        </div>
      </div>
    </div>
  )
}
